import type { UserLoginInput, UserOutput, UserRegisterInput } from '@graphql/types';

import type { Observable } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import * as decode from 'jwt-decode';
import { BehaviorSubject, map } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private readonly apollo = inject(Apollo);
	readonly payload = new BehaviorSubject<decode.JwtPayload | null>(null);

	register(data: UserRegisterInput): Observable<UserOutput | unknown> {
		return this.apollo
			.mutate<UserOutput>({
				mutation: gql`
					mutation RegisterUser($data: UserRegisterInput!) {
						registerUser(data: $data) {
							code
							id
							username
							email
							password
							dateCreated
						}
					}
				`,
				variables: {
					data,
				},
			})
			.pipe(
				map((data) => {
					this.payload.next(this.parseJwt());

					return data.data;
				}),
			);
	}

	login(data: UserLoginInput): Observable<UserOutput> {
		return this.apollo
			.query<UserOutput>({
				query: gql`
					query LoginUser($data: UserLoginInput!) {
						loginUser(data: $data) {
							code
							id
							username
							email
							password
							dateCreated
						}
					}
				`,
				variables: {
					data,
				},
			})
			.pipe(map((data) => data.data));
	}

	logout() {
		this.apollo
			.query({
				query: gql`
					query Logout {
						logout
					}
				`,
			})
			.pipe(
				map(() => {
					this.payload.next(null);
				}),
			);
	}

	parseJwt() {
		const token = getCookie('refreshToken')?.split('=')[1];

		if (token) {
			return decode.jwtDecode(token);
		}
		return null;
	}
}

export function getCookie(name: string) {
	const cookies = document.cookie.split('; ');
	for (const cookie of cookies) {
		const [key, value] = cookie.split('=');
		if (key === name) {
			return `${name}=${value}`;
		}
	}
	return null;
}
