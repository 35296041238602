import type { UserOutput, UserUpdateInput } from '@graphql/types';
import type { Observable } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs';

export interface UserInfo {
	data: UserOutput;
}

@Injectable({
	providedIn: 'root',
})
export class AccountService {
	private readonly apollo = inject(Apollo);

	readonly accountInfo$ = this.apollo
		.query<UserInfo>({
			query: gql`
				query UserInfo {
					data: getUser {
						gameId
						username
						password
						email
						code
						id
					}
				}
			`,
			pollInterval: 10000,
		})
		.pipe(map(({ data }) => data.data));

	updateAccount(data: UserUpdateInput): Observable<UserOutput> {
		return this.apollo
			.mutate<UserInfo>({
				mutation: gql`
					mutation UpdateInfo($data: UserUpdateInput!) {
						data: updateUser(data: $data) {
							username
						}
					}
				`,
				variables: {
					data,
				},
			})
			.pipe(map(({ data }) => data!.data));
	}
}
