import type { ApplicationConfig } from '@angular/core';
import { HttpClient, provideHttpClient } from '@angular/common/http';
import { importProvidersFrom } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { HttpLink, InMemoryCache } from '@apollo/client';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { provideMarkdown } from 'ngx-markdown';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';

function concatUrl(): string {
	return `${environment.url.base}:${environment.url.port}${environment.url.graphqlRoute}`;
}

export const appConfig: ApplicationConfig = {
	providers: [
		provideRouter(appRoutes),
		provideMarkdown({ loader: HttpClient }),
		importProvidersFrom(ApolloModule),
		{
			provide: APOLLO_OPTIONS,
			useFactory: () => {
				return {
					cache: new InMemoryCache(),
					link: new HttpLink({
						uri: concatUrl(),
					}),
				};
			},
		},
		provideHttpClient(),
		provideAnimations(),
	],
};
